import isSmartphone from '../utils/isSmartPhone';

window.seminarsClick = function(url, route, brand_id, seminar_id) {
  window.externalClick('seminars', url, route, brand_id, seminar_id);
}

window.ctaClick = function(url, route, brand_id, index) {
  window.externalClick('cta', url, route, brand_id, index);
}

window.campaignClick = function(url, route, brand_id, index) {
  window.externalClick('campaign', url, route, brand_id, index);
}

window.externalClick = function(type, url, route, brand_id, value) {
  window.gtag('event', window.location.pathname, {
    event_category: `external_${type}_click_${route}`,
    event_label: isSmartphone() ? `SP_${brand_id}` : `PC_${brand_id}`,
    value: value,
    transport_type: 'beacon',
    send_to: 'UA-153534803-42'
  });
  window.gtag('event', window.location.pathname, {
    event_category: `external_${type}_click_${route}`,
    event_label: isSmartphone() ? `SP_${brand_id}` : `PC_${brand_id}`,
    value: value,
    transport_type: 'beacon',
    send_to: 'UA-36943659-30'
  });
  window.open(url);
}
